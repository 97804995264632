<template>
  <div>
    <hero-bar :has-right-visible="false">
      {{ $getTranslation('general.views.survey', 1, true) }}
    </hero-bar>
    <section v-if="ability.can('create', 'survey')" class="section is-main-section">
      <h1 class="title">Toolbar</h1>
      <b-button type="is-primary" icon-left="plus">Create Survey</b-button>
    </section>
    <section class="section">
      <h1 class="title">{{ $getTranslation('surveys.title.surveyOverview') }}</h1>
      <SurveyList v-if="items !== null"
        :tabs="tabOrder"
        :paginated="paginated"
        :per-page="perPage"
        :surveys="items"
      />
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ApiStats } from '@/api-routes.js'
import ListTemplateMixin from '@/mixins/ListTemplateMixin'
import HeroBar from '@/components/HeroBar'
import SurveyList from '@/components/surveys/List'

export default {
  name: 'home',
  mixins: [ListTemplateMixin],
  components: {
    SurveyList,
    HeroBar
  },
  data () {
    return {
      endpoint: ApiStats,
      tabOrder: ["active", "upcoming", "finished"],
    }
  },
  computed: {
    ...mapGetters(["abilities"])
  },

  methods: {
    //
  },

  created() {
    // this.$store
    // .dispatch("fetchStatsOverview")
    this.fetchItems (this.endpoint)
      .then(fetchedSurveys => {
        // console.log('fetched survey', fetchedSurveys)
      })
      .catch(error => {
        // this.isLoading = false
        this.$buefy.toast.open({
          message: `Error loading items `,
          type: "is-danger"
        })
        console.log("Error while fetching Stats Overview: ", error);
      })
  }
}
</script>
